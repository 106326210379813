<script>
export default {
  name: "Slider",
};
</script>
<template>
  <section class="section bg-home height-100vh" id="home" :style="{
    'background-image': 'url(' + require('@/assets/images/bg/202506.jpg') + ')',
    'background-size': 'cover',
    'background-position': 'center',
  }">
    <!-- <div class="bg-overlay"></div> -->
    <div class="slidero">
      <div class="text-white text-center sliderarea">
        <!-- <div class="col-12 text-white text-center">
          <p class="slider_title_top mfont">以专业服务为客户创造价值</p>
          <p class="slider_title_center ofont">
            CREATE VALUE FOR CUSTOMERS WITH SERVICES
          </p>
          <p class="slider_title_bottom ofont">JIYUAN INFORMATION TECHNOLOGY</p>
        </div> -->
      </div>
    </div>
  </section>
</template>
<style scoped>
.sliderarea {
  display: flex;
  justify-content: center;
}
</style>
